import React, {useEffect, useState} from "react"
import {observer} from "mobx-react-lite"
import {useRootStore} from "../../RootStateContext"
import Loader from "../Loader"
import ReactTooltip from 'react-tooltip'
import {BsListUl, BsGrid3X3GapFill} from 'react-icons/bs'
import {BiStopwatch} from 'react-icons/bi'

const List = observer(() => {
    const {marketsStore, authStore} = useRootStore()
    const {marketsList, load, setCurrentMarket, currentMarket, loadSells, loadCollections} = marketsStore
    const [gridCLass, setGridClass] = useState('cardGrid')
    const [isGrid, setIsGrid] = useState(true)
    const [isList, setIsList] = useState(false)

    useEffect(() => {
        load()
        loadSells()
        loadCollections()
    }, [])


    if (marketsList.length === 0) {
        return <Loader/>
    }

    let toGrid = () => {
        setGridClass('cardGrid')
        setIsGrid(true)
        setIsList(false)
    }

    let toList = () => {
        setGridClass('cardList')
        setIsGrid(false)
        setIsList(true)
    }

    return (
        <>
            <div className="gridChanger">
                <button onClick={toGrid} className={isGrid ? 'active' : ''}>
                    <BsGrid3X3GapFill size={25}/>  
                </button>

                <button onClick={toList} className={isList ? 'active' : ''}>
                    <BsListUl size={25}/>  
                </button>
            </div>
            <div className={"listWrapper__" + gridCLass}>
                {marketsList.map(market => {
                    let coinSumTube = 0
                    let coinSumbox = 0
                    let cashSum = 0
                    Object.keys(Object.assign({}, market.condition?.devices?.coin.data.level)).forEach(coin => {
                        let value = parseInt(coin) / 100
                        coinSumTube += value * market.condition?.devices?.coin.data.level[coin]
                    })
                    Object.keys(Object.assign({}, market.condition?.devices?.coin.data.cashbox_level)).forEach(coin => {
                        let value = parseInt(coin) / 100
                        coinSumbox += value * market.condition?.devices?.coin.data.cashbox_level[coin]
                    })
                    Object.keys(Object.assign({}, market.condition?.devices?.billvalidator.data.level)).forEach(coin => {
                        let value = parseInt(coin) / 100
                        cashSum += value * market.condition?.devices?.billvalidator.data.level[coin]
                    })
                    return (
                        <div key={market.id} onClick={() => setCurrentMarket(market)} className={gridCLass}>
                            <div className={gridCLass + '__head'}>
                                <div className={gridCLass + "__head__left"}>
                                    <div className={gridCLass + "__head__number"}>{market.number}</div>
                                    <div className={gridCLass + "__head__address"}>{market.address}</div>
                                </div>
                                <div className={gridCLass + "__head__right"}>
                                    <BiStopwatch 
                                        size={30} 
                                        data-for="time" 
                                        data-place="top" 
                                        data-effect="solid" 
                                        data-tip="Последный выход на связь"
                                        className='cardtime cardtime--danger'
                                    />
                                    <ReactTooltip id="time" className='tooltip'/>
                                </div>
                            </div>

                            <div className={gridCLass + "__content"}>
                                <div className={gridCLass + "__content__first"} data-for="events" data-place="top" data-effect="solid" data-tip="События">
                                    <div className={`block ${market.events_active_count ? "danger" : ""}`}>{market.events_active_count}</div>
                                    <ReactTooltip id="events" className='tooltip'/>
                                </div>
                                <div className={gridCLass + "__content__second"}>
                                    <div className="productWater block" data-for="water" data-place="top" data-effect="solid" data-tip="Вода">
                                        {Math.ceil(market.condition?.calculated.water_in_tank) || '0'}
                                        <ReactTooltip id="water" className='tooltip'/>
                                    </div>
                                    <div className="productBottle block" data-for="bottle" data-place="top" data-effect="solid" data-tip="Тара">
                                        {market.condition?.calculated.bottles_in_kiosk + market.condition?.calculated.bottles_in_tray || '0'}
                                        <ReactTooltip id="bottle" className='tooltip'/>
                                    </div>
                                    {/* <div className="productCard" data-for="card" data-place="top" data-effect="solid" data-tip="Карта">
                                        {market.products?.card}
                                        <ReactTooltip id="card"/>
                                    </div> */}
                                    <div className="productCork block" data-for="cork" data-place="top" data-effect="solid" data-tip="Пробка">
                                    {market.condition?.calculated.corks_in_kiosk + market.condition?.calculated.corks_in_tray || '0'}
                                        <ReactTooltip id="cork" className='tooltip'/>
                                    </div>
                                </div>
                                <div className={gridCLass + "__content__last"}>
                                    <div className="moneyCash block" data-for="cash" data-place="top" data-effect="solid" data-tip="Наличные">
                                        {coinSumTube + coinSumbox + cashSum}
                                        <ReactTooltip id="cash" className='tooltip'/>
                                    </div>
                                    <div className="moneyBank block" data-for="bank" data-place="top" data-effect="solid" data-tip="Безналичные">
                                        {'0'}
                                        <ReactTooltip id="bank" className='tooltip'/>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    )
                })}
            </div>
        </>
    )
})

export default List