import {observable, action, makeAutoObservable, runInAction, set} from "mobx"
import {
    getMarkets,
    sendComand,
    getSells,
    getCollections,
    getQr,
    putEvents,
    getEvents,
    putAllEvents,
    getMarketGroups,
    createMarketGroup, updateMarketGroup, deleteMarketGroup, updateMarket
} from "../api/markets"
import { commandActive, commandMessage, CurrentSell, MarketEvents, MarketMoney, MarketProducts, MarketGroup } from "./interfaces/marketInterfaces"

interface Entity {
    id: number,
    name: string
}

export interface Market {
    id: number,
    serial: number,
    group_id: number,
    address: string,
    number: string,
    products?: MarketProducts,
    events?: MarketEvents[],
    money?: MarketMoney,
    condition: any,
    events_active_count: number,
    stats?: any,
    version?: string,
    entity?: Entity
}

export class MarketsStore {

    constructor() {
        makeAutoObservable(this);
    }

    @observable markets: Market[] = [];
    @observable currentMarket?: Market;

    @observable groups: MarketGroup[] = [];
    @observable currentGroupId?: number;

    @observable pageReloadTime?: number;
    @observable currentMarketQr?: any;
    @observable currentMarketEvents?: any[];
    @observable currentMarketActiveEvents?: number[] = [];
    @observable currentMarketSells?: any[];
    @observable currentSell?: CurrentSell;
    @observable currentMarketCollections?: any[];


    @observable marketsList: Market[] = [];
    @observable commandsActive: commandActive[] = []
    @observable commandsMessage: commandMessage[] = []

    @action setCurrentGroupId = (groupId: number) => {
        this.currentGroupId = groupId;
        this.marketsList = this.markets.filter((market: Market) => {return market.group_id === groupId})
    }

    @action resetFilter = () => {
        this.currentGroupId  =  0
        this.marketsList = this.markets
    }

    @action loadGroups = (reload: boolean = false) => {
        if (this.groups.length > 0 && !reload) {
            return
        }

        getMarketGroups()
            .then( data => {
                runInAction(()=>{
                    this.groups = data;
                })

            })
            .catch( e => {
                console.log('loadError', e)
            })
    }

    @action createGroup = (name: string) => {
        createMarketGroup(name)
            .then( data => {
                this.groups.push(data)
            })
            .catch( e => {
                console.log('Create error', e)
            })

    }

    @action updateGroup = (id: number, name: string) => {
        updateMarketGroup(id, name)
            .then( data => {
                this.groups = this.groups.map( g => {
                    if (g.id === data.id) {
                        g.name = data.name
                    }
                    return g;
                })
            })
            .catch( e => {
                console.log('Update error', e)
            })
    }

    @action deleteGroup = (id: number) => {
        deleteMarketGroup(id)
            .then( data => {
                this.groups = this.groups.filter( g => g.id !== id)
            })
            .catch( e => {
                console.log('Delete error', e)
            })
    }

    @action loadMarkets = () => {
        getMarkets()
            .then( data => {
                runInAction(() => {
                    this.markets = data.map( (m: any) => {
                        if (m.stats) {
                            if (m.stats.last_ping_date) {
                                m.stats.last_ping_date = new Date(m.stats.last_ping_date * 1000)
                            }

                            if (m.stats.last_selling_date) {
                                m.stats.last_selling_date = new Date(m.stats.last_selling_date * 1000)
                            }
                        }
                        return m
                    })
                    this.marketsList = this.markets
                })

            })
            .catch( e => {
                console.log('loadError', e)
            })
    }

    @action updateMarket = (id: number, settings: any) => {
        updateMarket(id, settings)
            .then(data => {
                console.log(data)
            })
            .catch( e => {
                console.error('Update market error', e)
            })
    }

    @action load = () => {
        this.loadGroups()
        this.loadMarkets()
    }

    @action loadSells = () => {
        if (this.currentMarket !== undefined) {
            this.currentMarketSells = []
            getSells(this.currentMarket.id)
                .then( data => {                                                         
                    this.currentMarketSells = data 
                    this.currentSell = this.currentMarketSells![0]               
                })
        }
    }


    @action setCurrentSell = (sell: CurrentSell) => {
        this.currentSell = sell
    }

    @action loadCollections = () => {
        if (this.currentMarket !== undefined) {
            this.currentMarketCollections = []
            getCollections(this.currentMarket.id)
                .then( data => {                    
                    this.currentMarketCollections = data      
                    // console.log('loadCollections', data);
                })
        }
    }

    @action loadQr = () => {
        if (this.currentMarket !== undefined) {
            this.currentMarketQr = []
            getQr(this.currentMarket.id)
                .then( data => {                  
                    this.currentMarketQr = data;                    
                })
        }
    } 
    
    @action loadEvents = () => {
        if (this.currentMarket !== undefined) {
            this.currentMarketQr = []
            getEvents(this.currentMarket.id)
                .then( data => {         
                    this.currentMarketEvents = data       
                    this.currentMarketActiveEvents = data.filter((event: { status: string; }) => event.status === "active").map((event: { id: any; }) => event.id)
                    console.log('loadEvents', data);
                    console.log('loadactiveEvents', this.currentMarketActiveEvents);
                               
                })
        }
    }

    @action setCurrentMarket = (market: Market) => {        
        if (this.currentMarket === undefined || this.currentMarket.id !== market.id) {
            this.currentMarket = market            
        } else {
            this.currentMarket = undefined
        }
        this.loadSells()
        this.loadCollections()
        this.loadQr()
    }

    @action putEvents = (eventId: number) => {        
        if(this.currentMarket !== undefined) {
            putEvents(this.currentMarket.id, eventId)
                .then( data => {
                    this.loadEvents()
                })
        }
    }

    @action putAllEvents = () => {        
        if(this.currentMarket !== undefined) {
            putAllEvents(this.currentMarket.id, this.currentMarketActiveEvents!)
                .then( () => {
                    this.loadEvents()
                })
        }
    }

    @action clearCurrentMarket = () => {        
        this.currentMarket = undefined
    }

    @action sendCommand = (command: string) => {
        if (this.currentMarket !== undefined) {
            let market_id = this.currentMarket?.id
            if (this.commandsActive.find(command => market_id === command.market)) {
                //Дождитесь окончания выполнения команды на данном киоске
                return;
            }
            this.commandsActive.push({key: command, market: market_id})
            let startDate = Date.now()
            sendComand(this.currentMarket.id, command)
                .then(data => {

                })
                .catch( e => {
                    this.commandsMessage.push({command: command, market_id: market_id, message: e.response.data.message})
                })
                .finally(()=> {
                    const animationTimeout = 500
                    let clearLock = () => {
                        this.commandsActive = this.commandsActive.filter( ac => !(ac.key === command && this.currentMarket?.id === ac.market))
                    }
                    let passedTime = Date.now() - startDate
                    if (passedTime < animationTimeout) {
                        setTimeout(clearLock, (animationTimeout - passedTime))
                    } else {
                        clearLock()
                    }
                })
        }
    }

    @action loadTimeout = () => {
        let that = this
        let time = parseInt(localStorage.getItem('timer') || '5') * 60 * 1000
        setTimeout(function run() {
            let time = parseInt(localStorage.getItem('timer') || '5') * 60 * 1000

            console.log('loadTimeout');
            
            that.loadMarkets()
            setTimeout(run, time)




        }, time)
    }
}