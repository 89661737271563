import React, {useEffect} from "react"
import {observer} from "mobx-react-lite"
import {useRootStore} from "../../RootStateContext"
// import PaymentIcon from '../shorts/paymentIcons/PaymentIcons'
// import { Container, Row, Col } from "react-bootstrap"

const Sells = observer(() => {
    const {marketsStore} = useRootStore()
    const {loadSells, currentMarketSells, setCurrentSell, currentSell} = marketsStore

    useEffect(() => {
        loadSells()
    }, [])

    const UpdateSell = (sell: any) => {
        console.log(sell);
        setCurrentSell(sell)
    }

    return (
        <div className="events">
            <table className="table table--border table--main">
                <tbody>
                    <tr>
                        <td rowSpan={5}>{currentSell?.date}</td>
                        <td colSpan={2}>Покупка</td>
                        <td>Оплата</td>
                    </tr>
                    <tr>
                        <td>Вода, л.</td>
                        <td>{currentSell?.water}</td>
                        <td rowSpan={2}>Внесено: 100 руб.</td>
                    </tr>
                    <tr>
                        <td>Тара, шт.</td>
                        <td>{currentSell?.bottle}</td>
                    </tr>
                    <tr>
                        <td>Пробка, шт.</td>
                        <td>{currentSell?.cork}</td>
                        <td rowSpan={2}>Возврат сдачи: {currentSell?.rest} руб.</td>
                    </tr>
                    <tr>
                        <td>Пополнение, руб.</td>
                        <td>{currentSell?.replenishment}</td>
                    </tr>
                    <tr>
                        <td>Показать лог</td>
                        <td colSpan={2}></td>
                        <td>Итого: {currentSell?.total} руб.</td>
                    </tr>
                </tbody>
            </table>


            <table className='table table--hover'>
                <thead>
                    <tr>
                        <td>Дата</td>
                        <td>Суммы</td>
                        <td>Итог</td>
                    </tr>
                </thead>
                <tbody>
                {currentMarketSells?.map((sell, i) => {
                        return (
                            <tr 
                                key={i}
                                onClick={() => UpdateSell(sell)}
                                className={sell?.id === currentSell?.id ? 'active' : ''}
                            >
                                <td>{sell.date}</td>
                                <td>{sell.water}/{sell.bottle}/{sell.cork}/{sell.replenishment}</td>
                                <td>{sell.total} руб.</td>
                            </tr> 
                        )
                    })}
                </tbody>
            </table>
            
            {/* <table className="table table--border table--hover">
                <tbody> 
                    {currentMarketSells?.map((sell, i) => {
                        return (
                            <tr 
                                key={i}
                                onClick={() => UpdateSell(sell)}
                                className={sell?.id === currentSell?.id ? 'active' : ''}
                            >
                                <td>{sell.date}</td>
                                <td>{sell.water}/{sell.bottle}/{sell.cork}/{sell.replenishment}</td>
                                <td>{sell.total} руб.</td>
                            </tr> 
                        )
                    })}
                </tbody>
            </table> */}
        </div>
    )
})

export default Sells