import React, {useEffect} from "react"
import {observer} from "mobx-react-lite"
import {useRootStore} from "../../RootStateContext"
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"

// import {IconContext} from "react-icons"
// import {VscDebugConsole, VscInfo, VscWarning, VscError} from 'react-icons/vsc'
// import {MdOutlineSmsFailed} from 'react-icons/md'
// import {GrStatusCritical} from 'react-icons/gr'

const Events = observer(() => {
    const {marketsStore} = useRootStore()
    const {putEvents, loadEvents, currentMarketEvents, putAllEvents} = marketsStore

    useEffect(() => {
        loadEvents()
    }, [])

    return (
        <div className="events">
            <Button 
                onClick={() => putAllEvents()}
                className='button--blue mb-3'
            >
                Прочитать все
            </Button>
            <div className="events">
                <table className='table table--events'>
                    <thead>
                        <tr>
                            <td>Дата</td>
                            <td>Сообщение</td>
                        </tr>
                    </thead>
                    <tbody>
                        {currentMarketEvents?.map( e => {
                            return(
                                <tr key={e.id}>
                                    <td>{e.date}</td>
                                    <td>
                                        {e.message}
                                        {e.status === 'active' && 
                                            <OverlayTrigger
                                                key={'top'}
                                                placement={'top'}
                                                overlay={
                                                    <Tooltip id={`tooltip-${'top'}`}>
                                                        Пометить как прочитанное
                                                    </Tooltip>
                                                }
                                                >
                                                <Button 
                                                    variant="primary"
                                                    onClick={() => putEvents(e.id)}
                                                >
                                                    &times;
                                                </Button>
                                            </OverlayTrigger>
                                        }
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            {/* {currentMarketEvents?.map( e => {
                return(
                    <div key={e.id} className="events__event">
                        <time className="events__time">{e.date}</time>
                        <div className="events__row">
                            <div className="events__icons">
                                {e.lvl === 'debug' && 
                                    <IconContext.Provider value={{className: "eventicon eventicon__debug"}}>
                                        <VscDebugConsole />
                                    </IconContext.Provider>
                                }
                                {e.lvl === 'info' &&
                                    <IconContext.Provider value={{className: "eventicon eventicon__info"}}>
                                        <VscInfo />
                                    </IconContext.Provider>
                                }
                                {e.lvl === 'failure' &&
                                    <IconContext.Provider value={{className: "eventicon eventicon__failure"}}>
                                        <MdOutlineSmsFailed />
                                    </IconContext.Provider>
                                }
                                {e.lvl === 'warning' &&
                                    <IconContext.Provider value={{className: "eventicon eventicon__warning"}}>
                                        <VscWarning />
                                    </IconContext.Provider>
                                }
                                {e.lvl === 'error' &&
                                    <IconContext.Provider value={{className: "eventicon eventicon__error"}}>
                                        <VscError />
                                    </IconContext.Provider>
                                }
                                {e.lvl === 'critical' && 
                                    <IconContext.Provider value={{className: "eventicon eventicon__critical"}}>
                                        <GrStatusCritical />
                                    </IconContext.Provider>
                                }                                
                            </div>
                            <div className="events__message">
                                ({e.date})&nbsp;{e.message}
                            </div>
                            {e.status === 'active' && 
                                <OverlayTrigger
                                    key={'top'}
                                    placement={'top'}
                                    overlay={
                                        <Tooltip id={`tooltip-${'top'}`}>
                                            Пометить как прочитанное
                                        </Tooltip>
                                    }
                                    >
                                    <Button 
                                        variant="primary"
                                        onClick={() => putEvents(e.id)}
                                    >
                                        &times;
                                    </Button>
                                </OverlayTrigger>
                            }
                        </div>
                    </div>
                )
            })} */}
        </div>
    )
})

export default Events