import React, {useEffect, useState} from "react"
import {observer} from "mobx-react-lite"
import {useRootStore} from "../../RootStateContext"
import Styles from "./Details.module.css"
import Loader from "../Loader"
// import getSymbolFromCurrency from 'currency-symbol-map'
// import Coins from "./details/Coins"
import Bills from "./details/Bills"
import Diagnostic from "./details/Diagnostic"
// import About from "./details/About"
// import Qr from "./details/Qr"
import CoinBox from "./details/CoinBox"
import CoinCassette from "./details/CoinCassette"
import Water from "./details/Water"
import {Button, Modal} from "react-bootstrap"
// import Production from "./details/Production"
import Tara from "./details/Tara"
import Corks from "./details/Corks"
import Sensors from "./details/Sensors"
import Acquiring from './details/Acquiring'

const modalText = 'Вы уверены, что хотите выполнить команду?'

const Details = observer(() => {
    const {marketsStore} = useRootStore()
    const {currentMarket, commandsActive, commandsMessage, sendCommand, loadQr} = marketsStore
    
    const [show1, setShow1] = useState(false)
    const [show2, setShow2] = useState(false)
    const [show3, setShow3] = useState(false)
    const [show4, setShow4] = useState(false)

    const handleClose1 = () => setShow1(false)
    const handleClose2 = () => setShow2(false)
    const handleClose3 = () => setShow3(false)
    const handleClose4 = () => setShow4(false)

    const handleCommand1 = () => {
        sendCommand('screenshot')
        setShow1(false)
    }
    const handleCommand2 = () => {
        sendCommand('lock')
        setShow2(false)
    }
    const handleCommand3 = () => {
        sendCommand('unlock')
        setShow3(false)
    }
    const handleCommand4 = () => {
        sendCommand('reboot')
        setShow4(false)
    }

    const handleShow = (id: number) => {
        switch (id) {
            case 1:
                setShow1(true)
                break;
            case 2:
                setShow2(true)
                    break;
            case 3:
                setShow3(true)
                break;
            case 4:
                setShow4(true)
                break;
        }
    }

    useEffect(() => {
        loadQr()
    }, [])

    if (currentMarket === undefined) {
        return <></>
    }

    // let currentCurrency;
    // currentMarket.condition?.devices?.billvalidator.data.version.currency === 'RUR' ? currentCurrency = 'RUB' : currentCurrency = currentMarket.condition?.devices?.billvalidator.data.version.currency;
    // const currency = getSymbolFromCurrency(currentCurrency)    

    let currentCommandsMessages = commandsMessage.filter( m => m.market_id === currentMarket.id)

    const commands = [
        {key: 'screenshot', title: 'Скришот', styleName: Styles.commandScreenshot, active: false, id: 1},
        {key: 'lock', title: 'Заблокировать', styleName: Styles.commandLock, active: false, id: 2},
        {key: 'unlock', title: 'Разблокировать', styleName: Styles.commandUnlock, active: false, id: 3},
        {key: 'reboot', title: 'Перезагрузить', styleName: Styles.commandReboot, active: false, id: 4}
    ].map( command => {
        if (commandsActive.find( ac => ac.market === currentMarket.id && ac.key === command.key)) {
            return (
                <div key={command.key}>
                    <div><Loader size={35}></Loader></div>
                    <div>{command.title}</div>
                </div>
            )
        }
        // return (
        //     <div key={command.key} className={command.styleName} onClick={e => sendCommand(command.key)}>
        //         <div></div>
        //         <div>{command.title}</div>
        //     </div>
        // )

        return (
            <div key={command.key} className={command.styleName} onClick={() => handleShow(command.id)}>
                <div></div>
                <div>{command.title}</div>
            </div>
        )
    });

    return (
        <>
            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title>Скриншот</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalText}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCommand1}>Да</Button>
                    <Button variant="primary" onClick={handleClose1}>Нет</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show2} onHide={handleClose2}>
                <Modal.Header closeButton>
                    <Modal.Title>Заблокировать</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalText}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCommand2}>Да</Button>
                    <Button variant="primary" onClick={handleClose2}>Нет</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show3} onHide={handleClose3}>
                <Modal.Header closeButton>
                    <Modal.Title>Разблокировать</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalText}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCommand3}>Да</Button>
                    <Button variant="primary" onClick={handleClose3}>Нет</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show4} onHide={handleClose4}>
                <Modal.Header closeButton>
                    <Modal.Title>Перезагрузить</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalText}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCommand4}>Да</Button>
                    <Button variant="primary" onClick={handleClose4}>Нет</Button>
                </Modal.Footer>
            </Modal>

            <div className="details__container details__container--fixed">
                <div className="details__info">
                    <div>{currentMarket.entity &&
                        <>{currentMarket.entity.name}</>
                    }</div>
                    <div>Серийный номер {currentMarket.serial}</div>
                    <div>Версия ПО {currentMarket.version}</div>
                    {currentMarket.stats && currentMarket.stats.last_selling_date &&
                        <div>Последняя продажа {currentMarket.stats.last_selling_date.toLocaleString('ru-Ru')}</div>
                    }
                    {currentMarket.stats && currentMarket.stats.last_ping_date &&
                        <div>Последний выход на связь {currentMarket.stats.last_ping_date.toLocaleString('ru-Ru')}</div>
                    }

                </div>
                <div className="details__header details__header--big">{currentMarket.number} {'\u00A0'} {currentMarket.address} </div>

            </div>


            <div className="blocks">

                <div className="blocks__row">
                    <Water market={currentMarket}/>
                    <Tara market={currentMarket}/>
                    <Corks market={currentMarket}/>
                </div>

                <div className="blocks__row">
                    <Bills market={currentMarket}/>
                    <CoinBox market={currentMarket}/>
                    <CoinCassette market={currentMarket}/>
                    <Acquiring market={currentMarket}/>
                </div>

                <div className="blocks__row">
                    <div className="details_block details_block--commands details_block--wide">
                        <h4 className="text-center details__header">Отправить команду</h4>
                        <div className={Styles.commandsContainer + ' commands'}>
                            {commands}
                        </div>
                        <div>
                            {currentCommandsMessages.map( (m, key) => (<div key={key}>{m.message}</div>))}
                        </div>
                    </div>
                    <Diagnostic/>
                    <Sensors/>
                </div>
            </div>
        </>
    )
})

export default Details
