import React from "react"
import {VictoryBar, VictoryChart, VictoryAxis, VictoryContainer, VictoryTheme} from 'victory'

type corksProps = {
    market: any
}

const Corks = ({market}: corksProps) => {
    const colors = ["#2B3E50", "#4F2B50", "#503D2B"]
    let index = 0
    let data = []
    let tickFormat = []


    let cit = parseInt(market?.condition?.calculated.corks_in_tray)
    if (!isNaN(cit) && cit>0) {
        data.push({
            quarter: index + 1,
            count: cit,
            fill: colors[index],
            label: cit
        })
        tickFormat.push("В лотке")
        index++
    }

    let cik = parseInt(market?.condition?.calculated.corks_in_kiosk)
    if (!isNaN(cik) && cik>0) {
        data.push({
            quarter: index + 1,
            count: cik,
            fill: colors[index],
            label: cik
        })
        tickFormat.push("На полке")
        index++
    }

    let dc = parseInt(market?.stats?.daily_cork)
    if (!isNaN(dc) && dc>0) {
        data.push({
            quarter: index + 1,
            count: dc,
            fill: colors[index],
            label: dc
        })
        tickFormat.push("Продано")
    }

    if (data.length === 0) {
        return <></>
    }
    return (
        <div className="details_block details_block--sm">
            <h4 className="text-center details__header">Пробки</h4>

            <VictoryChart
                domainPadding={{ x: 5 }}
                //height={200}
                maxDomain={{ x: 3 }}
                theme={VictoryTheme.material}
                containerComponent={
                    <VictoryContainer
                        className="myChart"
                    />
                }
            >
                <VictoryAxis
                    tickValues={[1, 2, 3]}
                    tickFormat={tickFormat}
                    style={{
                        tickLabels: {
                            fontSize: 20
                        }
                    }}
                />
                <VictoryBar
                    data={data}
                    x="quarter"
                    y="count"
                    barRatio={1.2}
                    //barWidth={50}
                    style={{
                        data: {
                            fill: ({ datum }) => datum.fill,
                            stroke: ({ datum }) => datum.fill,
                            fillOpacity: 0.7,
                            strokeWidth: 3
                        },
                        labels: {
                            fontSize: 20
                        }
                    }}
                />
            </VictoryChart>
        </div>
    )
}

export default Corks
