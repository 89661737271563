import React from "react"
import {VictoryBar, VictoryChart, VictoryAxis, VictoryContainer, VictoryTheme} from 'victory'

type waterProps = {
    market: any
}

const Water = ({market}: waterProps) => {
    const colors = ["#2B3E50", "#4F2B50", "#503D2B"]
    let index = 0
    let data = []
    let tickFormat = []

    let wit = parseFloat(market?.condition?.calculated.water_in_tank)
    if (!isNaN(wit) && wit>0) {
        data.push({
            quarter: index + 1,
            count: wit,
            fill: colors[index],
            label: wit.toFixed(2)
        })
        tickFormat.push("В баке")
        index++
    }

    let dw = parseFloat(market?.stats?.daily_water)
    if (!isNaN(dw) && dw>0) {
        data.push({
            quarter: index + 1,
            count: dw,
            fill: colors[index],
            label: dw.toFixed(2)
        })
        tickFormat.push("Продано")
        index++
    }

    /*
    @TODO
    let wz = parseFloat()
    if (!isNaN(wz) && wz>0) {
        data.push({
            quarter: index + 1,
            count: dw,
            fill: colors[index],
            label: dw
        })
        tickFormat.push("Заправлено")
        index++
    }
     */

    return (
        <div className="details_block details_block--sm">
            <h4 className="text-center details__header">Вода</h4>


            <VictoryChart
                domainPadding={{ x: 5 }}
                maxDomain={{ x: 3 }}
                theme={VictoryTheme.material}
                containerComponent={
                    <VictoryContainer
                        className="myChart"
                    />
                }
            >
                <VictoryAxis
                    tickValues={[1, 2, 3]}
                    tickFormat={tickFormat}
                    style={{
                        tickLabels: {
                            fontSize: 20
                        }
                    }}
                />
                <VictoryBar
                    data={data}
                    x="quarter"
                    y="count"
                    barRatio={1.2}
                    style={{
                        data: {
                            fill: ({ datum }) => datum.fill,
                            stroke: ({ datum }) => datum.fill,
                            fillOpacity: 0.7,
                            strokeWidth: 3
                        },
                        labels: {
                            fontSize: 20
                        }
                    }}
                />
            </VictoryChart>

            <p className="text--12">Заправка: {market?.condition?.fill_water.time ? (new Date(market.condition?.fill_water.time)).toLocaleDateString() : '-'}</p>
            <p className="text--12">Производство: {market?.condition?.fill_water.production_time ? (new Date(market.condition?.fill_water.production_time)).toLocaleDateString() : '-'}</p>
        </div>
    )
}

export default Water