import React from "react"
import {VictoryBar, VictoryChart, VictoryAxis, VictoryContainer, VictoryTheme} from 'victory'

type taraProps = {
    market: any
}

const Tara = ({market}: taraProps) => {
    const colors = ["#2B3E50", "#4F2B50", "#503D2B"]
    let data = []
    let tickFormat = []
    let index = 0

    let bit = parseInt(market?.condition?.calculated.bottles_in_tray)
    if (!isNaN(bit) && bit>0) {
        data.push({
            quarter: index + 1,
            count: bit,
            fill: colors[index],
            label: bit
        })
        tickFormat.push("В лотке")
        index++
    }

    let bik = parseInt(market?.condition?.calculated.bottles_in_kiosk)
    if (!isNaN(bik) && bik>0) {
        data.push({
            quarter: index + 1,
            count: bik,
            fill: colors[index],
            label: bik
        })
        tickFormat.push("На полке")
        index++
    }

    let db = parseInt(market?.stats?.daily_bottle)
    if (!isNaN(db) && db>0) {
        data.push({
            quarter: index + 1,
            count: db,
            fill: colors[index],
            label: db
        })
        tickFormat.push("Продано")
        index++
    }

    if (data.length === 0){
        return <></>
    }

    return (
        <div className="details_block details_block--sm">
            <h4 className="text-center details__header">Тара</h4>

            <VictoryChart
                domainPadding={{ x: 5 }}
                //height={200}
                maxDomain={{ x: 3 }}
                theme={VictoryTheme.material}
                containerComponent={
                    <VictoryContainer
                        className="myChart"
                    />
                }
            >
                <VictoryAxis
                    tickValues={[1, 2, 3]}
                    tickFormat={tickFormat}
                    style={{
                        tickLabels: {
                            fontSize: 20
                        }
                    }}
                />
                <VictoryBar
                    data={data}
                    x="quarter"
                    y="count"
                    barRatio={1.2}
                    //barWidth={50}
                    style={{
                        data: {
                            fill: ({ datum }) => datum.fill,
                            stroke: ({ datum }) => datum.fill,
                            fillOpacity: 0.7,
                            strokeWidth: 3
                        },
                        labels: {
                            fontSize: 20
                        }
                    }}
                />
            </VictoryChart>
        </div>
    )
}

export default Tara
